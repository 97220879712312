import React from "react";
import PropTypes from "prop-types";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import "../styles/terms_and_conditions_page.scss";

export class TermsAndConditionsPageTemplate extends React.Component {

    render() {
        const {page} = this.props;

        return (
            <article className="terms-and-conditions">
                <div className="terms-and-conditions-container container">
                    <section className="terms-and-conditions-header">
                        <div className="terms-and-conditions-titleWrapper">
                            <h1 className="terms-and-conditions-title">{page.frontmatter.title}</h1>
                        </div>
                    </section>
                    <section className="terms-and-conditions-content section">
                        {page.bodyIsMarkdown ? (
                            <ReactMarkdown className="terms-and-conditions-description" source={page.html}/>
                        ) : (
                            <HTMLContent className="terms-and-conditions-description" content={page.html}/>
                        )}
                    </section>
                    <section className="terms-and-conditions-pdf">
                        <object data="/pdf/AGBH.pdf" type="application/pdf">
                        </object>
                    </section>
                </div>
            </article>
        );
    }

}

const TermsAndConditionsPage = ({data}) => {
    const {markdownRemark: page, footerData, navbarData} = data;
    const {
        frontmatter: {
            seo: {title: seoTitle, description: seoDescription, browserTitle},
        },
    } = page;

    return (
        <Layout footerData={footerData} navbarData={navbarData}>
            <Helmet>
                <meta name="title" content={seoTitle}/>
                <meta name="description" content={seoDescription}/>
                <title>{browserTitle}</title>
            </Helmet>
            <TermsAndConditionsPageTemplate page={{...page, bodyIsMarkdown: false}}/>
        </Layout>
    );
};

TermsAndConditionsPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default TermsAndConditionsPage;

export const TermsAndConditionsPageQuery = graphql`
  query TermsAndConditionsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title        
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
